<template>
  <div>
    <save
      ref="saveForm"
      :regions="regions.data"
      @onSuccess="getItems"
    />
    <div class="text-right">
      <b-button
        v-can="'warehouses.product_transfer_parties-store'"
        variant="success"
        class="mr-2 mb-1"
        @click="goTo('TransferPartyCreate')"
      >
        <feather-icon icon="RepeatIcon" />Перемещение товаров
      </b-button>
    </div>
    <b-overlay :show="loading">
      <good-table-column-search
        :items="items.data"
        :columns="columns"
        :total="items.total"
        :page="page"
        :filter="filterModel"
        model="WarehouseProducts"
        :has-show="hasPermission('warehouses.warehouses-show')"
        :has-delete="hasPermission('warehouses.warehouses-delete')"
        @getItems="getItems"
        @add="$refs.saveForm.visible = true"
        @edit="(item) => $refs.saveForm.edit(item)"
        @delete="destroy"
        @onPageChange="(p) => page = p"
      />
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'
import Save from './save.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch, Save,
  },
  data() {
    return {
      page: 1,
      loading: false,
      filterModel: {
        per_page: 50,
        number: null,
        address: null,
        region_id: null,
        city_id: null,
        calculating_type_id: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      regions: 'region/GET_ITEMS',
      items: 'warehouse/GET_ITEMS',
    }),
    columns() {
      return [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('Наименование(uz)'),
          field: 'name_uz',
          showField: this.hasPermission('warehouses.warehouses-show'),
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Наименование(ru)'),
          field: 'name_ru',
          showField: this.hasPermission('warehouses.warehouses-show'),
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Област'),
          field: this.nameByLocal('region.name_uz', 'region.name_ru'),
          filterOptions: {
            enabled: true,
            placeholder: this.$t('Все'),
            filterDropdownItems: [],
          },
        },
        {
          label: this.$t('Район'),
          field: this.nameByLocal('city.name_uz', 'city.name_ru'),
          filterOptions: {
            enabled: true,
            placeholder: this.$t('Все'),
            filterDropdownItems: [],
          },
        },
        {
          label: this.$t('Адрес'),
          field: 'address',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Действия'),
          field: this.hasPermission('warehouses.warehouses-update') ? 'action' : '',
        },
      ]
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.region_id': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.fetchCities({ region_id: newVal })
          .then(res => {
            this.mapCols('city', res)
          })
      } else if (newVal == null) {
        this.mapCols('city', null)
        this.filterModel.city_id = null
      }
    },
    // 'filterModel.city_id': function (newVal, oldVal) {
    //   debugger
    // },
  },
  mounted() {
    this.getItems()
    this.getRegionsAction({ per_page: 999 })
      .then(res => {
        this.mapCols('region', res)
      })
  },
  methods: {
    ...mapActions({
      getItemsAction: 'warehouse/index',
      getRegionsAction: 'region/index',
      destroyAction: 'warehouse/destroy',
      fetchCities: 'city/index',
    }),
    async getItems() {
      this.loading = true
      await this.getItemsAction({ ...this.filterModel, page: this.page })
      this.loading = false
    },
    nameByLocal(nameuz, nameru) {
      if (this.$i18n.locale == 'uz') { return nameuz }
      return nameru
    },
    destroy(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.destroyAction(id).then(res => {
              showToast('success', this.$t('Успешно удалено'))
              this.getItems()
            })
          }
        })
    },
    mapCols(filterName, res) {
      this.columns.map(item => {
        if (item.field === `${filterName}.name_uz` || item.field === `${filterName}.name_ru`) {
          if (res) {
            item.filterOptions.filterDropdownItems = res.data.data.map(i => {
              i.value = i.id
              i.text = this.nameByLocal(i.name_uz, i.name_ru)
              return i
            })
          } else {
            item.filterOptions.filterDropdownItems = [
              { value: null, text: '' },
            ]
          }
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
